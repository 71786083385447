.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50dvw circle at bottom left,
    #09ffdcd6 0%,
    #bb00ff5e 100%
  ),
  linear-gradient(135deg, #6fdc8c 10%, #1fab89 100%); /* Add background gradient */
  z-index: -1; /* Ensure it is behind the canvas */
}

canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: color-dodge; /* Change blend mode */
  filter: blur(10px); /* Adjust blur effect */
  opacity: 0.7;
}
